import { SubformProps } from "."

export const Submitted = ({ formData }: Partial<SubformProps>) => {
  return <div className="alert alert-success">
    <div className="mb-3"><b>Congratulations!</b></div>
    {formData.envelopes?.length ? <div className="mb-3">
      Your application has been submitted and is approved pending document verification.
      You should be receiving a package from Docusign that should be signed
      within 72 hours to complete the process.
    </div> : <div>
      Your application has been pre-qualified pending document verification. A member of
      our banking team will reach out within 48 business hours.
    </div>}
  </div>
}

