import { dollars } from "."

export type Adder = {
  description: string
  amount: number
}

export enum AdderTypes {
  RoofPrice = 'Roof Price',
  StoragePrice = 'Storage Price',
  TreeRemovalTrimmingsPrice = 'Tree Removal or Trimmings Price',
  HvacPrice = 'HVAC Price',
  Downpayment = 'Downpayment',
  Other = 'Other'
}

export class Adders {
  private adders: Adder[]

  constructor(adders: Adder[]) {
    this.adders = adders
  }

  getTotal(): number {
    return this.adders.reduce((acc, adder) => acc + adder.amount, 0)
  }

  getBreakdown(): string {
    return this.adders.map(a => {
      let descr = a.description
      if (descr === AdderTypes.Other) {
        descr = `${descr}*`
      } 
      return `${descr}: ${dollars(a.amount)}`
    }).join('\n')
  }

  getDownpayment(): number {
    return this.adders.find(a => a.description === AdderTypes.Downpayment)?.amount || 0
  }

  getStoragePrice(): number {
    return this.adders.find(a => a.description === AdderTypes.StoragePrice)?.amount || 0
  }

  getRoofPrice(): number {
    return this.adders.find(a => a.description === AdderTypes.RoofPrice)?.amount || 0
  }

  static getNonDownPaymentAdders = (adders, equipmentType) => {
    return adders
      ?.filter(adder => adder.description !== AdderTypes.Downpayment)
      ?.map(adder => ({
        description: adder.description === AdderTypes.StoragePrice && 
          ['tesla-roof', 'tesla-solar'].includes(equipmentType) 
            ? 'Powerwall Total Price' 
            : adder.description,
        amount: dollars(adder.amount)
      }))
  } 
}