import { dateAndTime, errorToString, InstallerRegistrationPayload, StatusData } from '@oneethos/shared'
import { toast } from 'react-toastify'
import { GrTest } from 'react-icons/gr'
import api from '../api-client'
import ActionLink from './action-link'
import { Link } from './link'
import { useAppState } from '../hooks'
import { FaExternalLinkAlt } from 'react-icons/fa'

type ProjectActionsProps = {
  project: StatusData
  installer: InstallerRegistrationPayload
}

export const ProjectActions = ({ project, installer }: ProjectActionsProps) => {
  const { tenant } = useAppState()

  return (
    <div className="project-actions" style={{ textAlign: 'right' }}>
      {installer.isSupport &&
        <ActionLink
          label='Send New Referral Link'
          action={() => api.post(`/loanapps/${project.id}/send-new-referral`)}
          doneLabel='Sent New Referral Link'
        />
      }
      {project.installersStatus === 'Awaiting Application' ? <>
        <ActionLink
          label="Re-send referral email"
          action={() => api.post(`/loanapps/${project.id}/resend-referral`)}
          doneLabel="Resent referral email"
        />
        {installer.isSupport && project.projectType === 'residential' ? <Link
          label={`View application`}
          target={'_blank'}
          href={`/apply/${project.lookup}`} />
          : null}
      </> : null}
      {installer.isSupport ? <Link
        label={`Find Prequal Reports`}
        href={`/prequal-reports?loanAppId=${project.id}`}
      /> : null}
      {process.env.REACT_APP_NODE_ENV === 'test' && installer.isSupport ? <>
        <ActionLink
          label={<>Send Test Notification <GrTest title="Test Only"/></>}
          variant="secondary"
          action={() => api.post(`/loanapps/${project.id}/test-notification`)}
          doneLabel="Test Notification(s) Sent"
        />
        <ActionLink
          label={<>Test DIT <GrTest title="Test Only" /></>}
          variant="secondary"
          action={() => {
            return api.post(`/loanapps/${project.id}/test-dit`)
              .then(({ url }) => { window.open(url, '_blank') })
              .catch(ex => { toast.error(errorToString(ex)) })
          }}
          doneLabel="Tested DIT"
        />
      </> : null}

      {installer.isSupport
        ? <>
          {project.envelopes?.length ? <div className="mt-2">
            <b>Docusign Envelopes</b>
            {project.envelopes?.map(e => <div key={e.envelopeId}>
              <Link href={`${tenant.config.docusign?.appUrl}/send/documents/details/${e.envelopeId}`} target="_blank">
                {dateAndTime(new Date(e.timestamp))}
                <FaExternalLinkAlt className="ms-2" />
              </Link>
            </div>)}
          </div> : null}
          {process.env.REACT_APP_NODE_ENV === 'test' ? <>
            <ActionLink
              label={<>Send Loan Docs <GrTest title="Test Only" /></>}
              variant="secondary"
              action={() => {
                return api.post(`/loanapps/${project.id}/send-envelope`)
                  .then(() => { /* noop */ })
                  .catch(ex => { toast.error(errorToString(ex)) })
              }}
              doneLabel="Loan Docs Sent!"
            />
          </> : null}
        </> : null}
      {/* installer.isSupport 
          ? <SendEnvelopeLink 
          project={project} 
          label="Send Preapproval" 
          template="preapproval" 
          /> : null}
        {installer.isSupport ? <button
          className="btn btn-sm btn-link link-secondary p-0"
          onClick={() => {
            api.get(`/c/loanapps/${project.id}/preapproval-letter`)
              .then(() => { })
              .catch(ex => toast.error(errorToString(ex)))
              .finally(() => {
                // setLoading(false)
              })
          }}
        >
          &nbsp;{`View Preapproval Letter`}
        </button>
          : null} */}
    </div>
  )
}

export default ProjectActions
