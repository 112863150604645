import React, { useContext, useEffect, useState } from "react"
import { StreetAddress, errorToString, PrequalResult } from "@oneethos/shared"
import { FormInput, SubformProps, Validation } from "."
import { AddressInputs } from "../../components/forms"
import { Validate } from '@oneethos/shared'
import api from '../../api-client'
import { GiPartyPopper } from 'react-icons/gi'
import { FaRegHandPaper } from "react-icons/fa"
import { getBrowserFingerprintData } from "../../browser-fingerprint"
import { useAppState, useUtmQuery } from "../../hooks"
import { AppContext } from "../../appContext"
import { requestLoginViaEmail, requestRegistrationViaEmail } from "../../components"
import { Spinner } from "@fluentui/react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { lappToFormData } from "./util"
import { CoborrowerPrequal } from "./coborrower-prequal"
import { PrequalLetter } from "./prequal-letter"
import { ReviewTerms } from "./review-terms"

const { validateFields, ValidatorMap } = Validate

export const QualificationCriteria = () => <ul>
  <li>Credit score must be 680 or higher</li>
  <li>Debt to income ratio must be 50% or less</li>
  <li>Discuss alternative financing options with your solar installer</li>
  <li>Consider including a Co-Borrower in your application</li>
</ul>


export const PreQualSuccess = () => {
  const { tenant } = useAppState()

  return <div className="prequal-success" style={{ textAlign: 'center' }}>
    <GiPartyPopper size={100} color="green" />
    <div className="p-3 fw-bold">
      Hooray! Based on the information you have provided, you are prequalified for
      a solar loan with {tenant.config.name}.
    </div>
  </div>
}

export const PreQualFailure = () => {
  const { tenant } = useAppState()

  return <div className="prequal-failure">
    <div>
      You might not qualify for a solar loan with {tenant.config.name}.
      Consider the following before applying.
    </div>
    <QualificationCriteria />
  </div>
}

export const PreQualResult = ({ result }) => {
  const { tenant } = useAppState()

  if (result === 'pass') {
    return <PreQualSuccess />
  } else if (result === 'fail') {
    return <PreQualFailure />
  } else if (result === 'no-hit') {
    return <div className="prequal-no-hit">
      <p>
        We couldn't find any information to determine whether you prequalify for a solar
        loan with {tenant.config.name}. Please consider the following before applying.
      </p>
      <QualificationCriteria />
    </div>
  }

  return null
}

export const EligibilityFields = ({ formData, update }: Partial<SubformProps>) => {
  return <>
    <div className="row">
      <div className="col-md-4">
        <FormInput
          field='firstName'
          value={formData.firstName}
          onChange={s => update('firstName', s)}
        />
      </div>
      <div className="col-md-2">
        <FormInput
          field='middleName'
          value={formData.middleName}
          emptyOk
          onChange={s => update('middleName', s)}
        />
      </div>
      <div className="col-md-6">
        <FormInput
          field='lastName'
          value={formData.lastName}
          onChange={s => update('lastName', s)}
        />
      </div>
    </div>
    <FormInput
      field='email'
      value={formData.email || ''}
      onChange={s => update('email', s)}
      disabled
    />
    <FormInput
      field='phone'
      value={formData.phone || ''}
      onChange={s => update('phone', s)}
    />
    <div className="form-group">
      <label>Do you live at a different address than where the panels will be installed?</label>
      <select
        className="form-select"
        value={formData.installedAtDifferentLocation}
        onChange={ev => {
          const val = ev.target.value as 'yes' | 'no'

          // initialize the state of installationAddress, or clear it
          update({
            ...formData,
            userAddress: val === 'yes' ? {
              state: 'FL',
              country: 'United States'
            } : formData.installationAddress,
            installedAtDifferentLocation: val
          })
        }}
      >
        <option value='no'>No</option>
        <option value='yes'>Yes</option>
      </select>
    </div>
    {formData.installedAtDifferentLocation === 'yes' ? (
      <AddressInputs
        address={formData.userAddress}
        label="Home Address"
        onChange={address => update('userAddress', address)}
      />
    ) : null}
    <FormInput
      field='statedGrossAnnualIncome'
      value={formData.statedGrossAnnualIncome}
      onChange={s => update('statedGrossAnnualIncome', s)}
    />
  </>
}

const RegistrationRequired = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const query = new URLSearchParams({ route: `/apply/${id}` })
  const url = `/login?${query.toString()}`

  return <div>
    <p>
      To protect your security, we have sent you an email to verify your information. Please
      check your email to continue the application process.
    </p>
    <div className="buttons text-start mb-3">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => {
          const query = new URLSearchParams({ route: `/apply/${id}` })
          navigate(`/verify-email?${query.toString()}`)
        }}
      >Enter Code</button>
    </div>
    <p>
      Didn't receive the email or having issues? You may
      try to <Link to={url}>login or register</Link> to
      resume your application.
    </p>
  </div>
}

export const PrequalComplete = ({ formData, update, onError, save }) => {
  const { prequalResult, prequalResultCombined } = formData

  const warn = (
    ['fail', 'no-hit'].includes(prequalResult) &&
    ['fail', 'no-hit'].includes(prequalResultCombined)
  )

  const prequalAcked = formData.preapprovalLetter?.agreement?.agrees === true

  return <div>
    <PreQualResult result={warn ? 'fail' : 'pass'} />
    <PrequalLetter formData={formData} update={update} />
    {prequalAcked ? <div>
      <div className={['form-group', warn ? 'alert alert-danger' : ''].join(' ')} style={{ cursor: 'pointer' }}>
        {warn ? <div className="mb-3 text-center"><FaRegHandPaper size={50} color="red" /></div> : null}
        <label>
          <input type="checkbox"
            onChange={ev => {
              update('agreesToProceedWithHardCreditInquiry', ev.target.checked ? {
                browser: getBrowserFingerprintData(),
                timestamp: new Date(),
                agrees: true
              } : false)
            }}
            checked={formData.agreesToProceedWithHardCreditInquiry?.agrees || false}
          />
          &nbsp;
          <b>
            I understand that if I decide to proceed with my application, this will
            result in a hard credit inquiry, which can impact my credit score.
          </b>
        </label>
      </div>
      <button type="button"
        className="btn btn-primary"
        disabled={formData.agreesToProceedWithHardCreditInquiry?.agrees !== true}
        onClick={() => save()}>Continue</button>
    </div> : null}
  </div>
}


/**
 * This component overlaps heavily with the CoborrowerPrequal component, but there are 
 * enough differences that it's likely best to keep them separate. Regardless, changes
 * here should prompt consideration of changes to the CoborrowerPrequal component.
 */
const PrimaryBorrowerPrequal = ({
  formData,
  schema,
  update,
  submitPrequal,
  installer
}) => {
  const [validation, setValidation] = useState<Record<string, string>>({})

  const validate = () => {
    const results = validateFields(schema, formData, [
      'firstName',
      'lastName',
      'phone',
      'email',
      'userAddress',
      'statedGrossAnnualIncome'
    ])

    if (formData.installedAtDifferentLocation === 'yes') {
      const err = ValidatorMap.address(formData.userAddress)
      if (err) {
        results['userAddress'] = 'Installation address must be complete'
      }
    }

    return results
  }

  useEffect(() => {
    // if the form is valid and the user is logged in, it's likely a user
    // returning from the registration process, attempt to submit the prequal
    const v = validate()
    if (!formData.prequalResult &&
      Object.keys(v).length === 0 &&
      installer && !installer.isSupport) {
      submitPrequal()
    }
  }, [])

  return <>
    <h2>Eligibility</h2>
    <p>Get started quickly to know if you're eligible. This will not affect your credit.</p>

    <EligibilityFields formData={formData} update={update} />

    <div className="buttons">
      <button className="btn btn-primary"
        onClick={() => {
          const v = validate()
          setValidation(v)
          if (Object.keys(v).length === 0) {
            submitPrequal()
          }
        }}>
        Check Eligibility
      </button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <div>
        {/* TODO: note that testing w/o a prequal report will NOT trigger adverse action */}
        <div className="alert alert-secondary mt-3">
          Test results will not trigger adverse action
          because a prequal record is required.
          <div className="buttons mt-3">
            {['pass', 'fail', 'no-hit'].map((test) => <button type="button" key={test}
              className="btn btn-secondary"
              onClick={() => {
                const v = validate()
                setValidation(v)
                if (Object.keys(v).length === 0) {
                  submitPrequal(test as 'pass' | 'fail' | 'no-hit')
                }
              }}
            >Check Eligibility ({test})</button>)}
          </div>
        </div>
      </div> : null}
    </div>
    {
      validation ? (
        <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
      ) : null
    }
  </>
}

export const Eligibility = ({ formData, schema, update, save, onError }: SubformProps) => {
  const { state: { registration: { installer } } } = useContext(AppContext)
  const [submitting, setSubmitting] = useState<'identity' | 'prequal' | 'combined' | ''>('')
  const [registrationRequired, setRegistrationRequired] = useState(false)
  const agreesToTerms = formData.agreesToTermsAndConditions?.agrees

  const handleError = (ex: any) => {
    if (ex.code === 'ALREADY_REGISTERED') {
      return requestLoginViaEmail({
        email: formData.email,
        route: `/apply/${formData.lookup}`
      }).then(() => setRegistrationRequired(true))
        .catch(ex => onError(errorToString(ex)))
    } else if (ex.code !== 'UNREGISTERED') {
      // most users have probably not registered because they're new referrals
      onError(errorToString(ex))
    }
  }

  const initiateRegistration = () => {
    setSubmitting('identity')

    return requestRegistrationViaEmail({
      email: formData.email,
      phone: formData.phone,
      name: `${formData.firstName} ${formData.lastName}`,
      route: `/apply/${formData.lookup}`
    }).then(() => setRegistrationRequired(true))
      .catch(handleError)
      .finally(() => setSubmitting(''))
  }

  const submitPrequal = async (testPrequalResult?: PrequalResult) => {
    setSubmitting(installer ? 'prequal' : 'identity')

    try {
      await save({
        data: { ...formData, testPrequalResult },
        advance: false
      })

      if (!installer) {
        await initiateRegistration()
      } else {
        const res = await api.post(`/c/loanapps/${formData._id}/prequal`)
        update(lappToFormData(res))
      }
    } catch (ex) {
      handleError(ex)
    }

    setSubmitting('')
  }

  const submitCombinedPrequal = async (testPrequalResultCombined?: 'pass' | 'fail' | 'no-hit') => {
    setSubmitting('combined')

    try {
      await save({
        data: { ...formData, testPrequalResultCombined },
        advance: false
      })

      const res = await api.post(`/c/loanapps/${formData._id}/prequal-combined`)
      update(lappToFormData(res))
      setSubmitting('')
    } catch (ex) {
      onError(errorToString(ex))
      setSubmitting('')
    }
  }

  useEffect(() => {
    if (!installer && formData.prescreened && agreesToTerms) {
      initiateRegistration()
    }
  }, [])

  if (registrationRequired) {
    return <RegistrationRequired />
  }

  if (submitting === 'prequal') {
    return <div>
      <p>Checking your eligibility...</p>
      <Spinner />
    </div>
  } else if (submitting === 'identity') {
    return <div>
      <p>Verifying security requirements...</p>
      <Spinner />
    </div>
  } else if (submitting === 'combined') {
    return <div>
      <p>Checking combined eligibility (with coborrower)...</p>
      <Spinner />
    </div>
  }

  if (
    formData.prequalResult &&
    formData.prequalResultCombined
  ) {
    return <PrequalComplete
      formData={formData}
      update={update}
      onError={onError}
      save={save}
    />
  }

  if (formData.prequalResult === 'pass') {
    return <PrequalComplete
      formData={formData}
      update={update}
      onError={onError}
      save={save}
    />
  } else if (
    ['fail', 'no-hit'].includes(formData.prequalResult) &&
    formData.includeCoBorrower !== 'yes'
  ) {
    return <div>
      <PreQualFailure />
      <div className="buttons">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => update('includeCoBorrower', 'yes')}>Add Coborrower</button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            save({
              data: {
                ...formData,
                declinedToAddCoborrowerDate: new Date(),
                includeCoBorrower: 'no'
              },
              advance: false
            })
          }}>I do not wish to add a coborrower</button>
      </div>
    </div>
  } else if (formData.includeCoBorrower === 'yes' && !formData.prequalResultCombined) {
    return <CoborrowerPrequal
      formData={formData}
      schema={schema}
      update={update}
      save={save}
      submitCombinedPrequal={submitCombinedPrequal}
      onError={onError}
    />
  }

  return <>
    <PrimaryBorrowerPrequal
      formData={formData}
      schema={schema}
      update={update}
      submitPrequal={submitPrequal}
      installer={installer}
    />
  </>
}

export default Eligibility