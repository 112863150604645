import { AdderTypes } from "@oneethos/shared"
import { FormInput, SubformProps } from "."
import { AddressInputs } from "../../components/forms"
import ApplicantForm from "./applicant-form"
import { CoborrowerFields } from "./coborrower"

export const ReviewApplication = ({ schema, formData, saving, update, save }: SubformProps) => {
  return <div>
    <h2>Review Application</h2>
    <div className="alert alert-warning">
      Please note that making changes at this step may affect your prequalification eligibility.
    </div>
    <AddressInputs
      address={formData.installationAddress || {}}
      label="Address where solar panels will be installed"
      onChange={address => {
        update({
          ...formData,
          installationAddress: address,
          userAddress: address
        })
      }}
    />
    <FormInput
      field='solarCost'
      value={formData.solarCost}
      disabled
    />
    <div className="row row-cols-1 row-cols-md-2 g-3">
      {formData.adders?.map((adder, index) => (
        <div className="col" key={index}>
          <FormInput
            field='adders'
            label={
              (formData.equipmentType === 'tesla-solar' || formData.equipmentType === 'tesla-roof') &&
                adder.description === AdderTypes.StoragePrice
                ? 'Powerwall Total Price'
                : adder.description
            }
            value={adder.description === AdderTypes.Downpayment ? `- ${adder.amount.toString()}` : adder.amount.toString()}
            disabled
          />
        </div>
      ))}
    </div>
    <FormInput
      field='loanAmount'
      value={formData.loanAmount}
      onChange={s => update('loanAmount', s)}
      disabled
    />
    <FormInput
      field='avgMonthlyUtilityBill'
      value={formData.avgMonthlyUtilityBill}
      onChange={s => update('avgMonthlyUtilityBill', s)}
    />

    {/* <h3>Contact Information</h3> */}
    <ApplicantForm
      applicant={formData}
      update={update}
      address={formData.userAddress}
      onAddressChange={addr => update('userAddress', addr)}
      disableEmail
      askForHeldInTrust
    />

    <FormInput
      field='statedGrossAnnualIncome'
      value={formData.statedGrossAnnualIncome}
      onChange={s => update('statedGrossAnnualIncome', s)}
    />

    {/* <h3>Coborrower</h3> */}
    <CoborrowerFields formData={formData} update={update} />

    <div className="alert alert-secondary">
      Changes to Income Verification documents can be made
      by navigating to the Income Verification step.
    </div>

    {/* <h3>Auto-Debit</h3> */}
    <div className="form-group">
      <label>I'd like to sign up for auto debit</label>
      <select
        className="form-select"
        value={formData.useAutoDebit || 'yes'}
        onChange={ev => update('useAutoDebit', ev.target.value)}
      >
        <option value='no'>No</option>
        <option value='yes'>Yes</option>
      </select>
    </div>

    {formData.useAutoDebit === 'yes' ? <>
      <FormInput
        field='autoDebitInstitution'
        onChange={s => update('autoDebitInstitution', s)}
        value={formData.autoDebitInstitution}
      />
      <FormInput
        field='autoDebitRoutingNo'
        onChange={s => update('autoDebitRoutingNo', s)}
        value={formData.autoDebitRoutingNo?.toString()}
      />
      <FormInput
        field='autoDebitAccountNo'
        onChange={v => update('autoDebitAccountNo', v)}
        value={formData.autoDebitAccountNo}
      />
    </> : null}

  </div>
}

export default ReviewApplication
