import React, { useState } from 'react'
import { FaBuilding, FaChevronDown, FaChevronRight, FaExclamationTriangle, FaHandHoldingHeart } from 'react-icons/fa'
import {
  Dictionary,
  Installer,
  LoggedUpload,
  StatusData,
  dateAndTime,
  dollars,
  InstallerStatus,
} from '@oneethos/shared'
import './project-list-item.scss'
import { Comment } from './comment'
import api from '../api-client'
import { toast } from 'react-toastify'
import { Link } from '.'
import { useAppState } from '../hooks'
import { DocumentUploadHistory, AdditionalDocumentUpload } from './documents'
import { Assignees } from './assignees'
import { FaHouseChimney, FaListCheck } from 'react-icons/fa6'
import { ProjectRequiredDocuments, ProgressBar } from '.'
import ProjectActions from './project-actions'
import { InstallersStatusConfig } from '../reducers/installersStatusReducer'
import { GrTest } from 'react-icons/gr'

export type NavOpt = 'history' | 'checklist' | 'other' | 'changeOrder'

type ProjectListItemProps = {
  project: StatusData & { hasUpload?: LoggedUpload }
  installersMap?: Dictionary<Installer>
  initialNav?: NavOpt
  autoExpandIfActionNeeded?: boolean
  onChange: (project: StatusData) => void
  onDoneEditingAssignees?: () => void
}

type InstallersStatusProps = {
  project: StatusData
  installersStatusConfig: InstallersStatusConfig
  onChange: (project: StatusData) => void
}

const InstallersStatus = ({ project, installersStatusConfig, onChange }: InstallersStatusProps) => {
  return <div className="pr-info"><b>Status:</b>
    {process.env.REACT_APP_NODE_ENV === 'test' ? <div className="d-inline-flex">
      <div className="d-flex align-items-center">
        <select className="form-select" value={project.installersStatus} onChange={e => {
          api.patch(`/loanapps/${project.id}`, {
            installersStatus: e.target.value
          }).then(data => {
            toast.success('saved status')
            onChange(new StatusData(data))
          }).catch(ex => toast.error(ex.error || ex.message, { autoClose: false }))
        }}>
          {installersStatusConfig.list.map((sc, idx) => (
            <option key={idx} value={sc.status}>{sc.status}</option>
          ))}
        </select>
        <FaExclamationTriangle className="ms-1" title="Only editable in test; manual changes may have undefined side effects" style={{ color: 'var(--oe-yellow)' }} />
        <GrTest title="Test Only" className="ms-1" />
      </div>
    </div> : project.installersStatus}
  </div>
}

const NextStep = ({ project }: { project: StatusData }) => {
  if (project.isClosed) return null

  return <div className="next-step">
    <div className="pr-info"><b>Next Step:</b> {project.description}</div>
    <div className="pr-info">
      <div><b>Action Required By:</b> {project.owner || 'Unknown'}</div>
      {project.hasUpload ? <div className="text-secondary text-small fst-italic">
        Documents uploaded at {dateAndTime(new Date(project.hasUpload.timestamp))}
      </div> : null}
    </div>
  </div>
}

const isBetweenStatus = (
  startStatus: string,
  status: string,
  endStatus: string,
  isc: Dictionary<InstallerStatus>
): boolean => {
  const start = isc[startStatus]
  const end = isc[endStatus]
  const current = isc[status]

  if ([start?.sequence, end?.sequence, current?.sequence].includes(undefined)) {
    console.warn('could not find status to check order', { start, end, current })
    return false
  }

  return start.sequence <= current.sequence && current.sequence <= end.sequence
}


export const ProjectListItem = ({
  project,
  installersMap,
  initialNav,
  autoExpandIfActionNeeded = false,
  onChange,
  onDoneEditingAssignees
}: ProjectListItemProps) => {
  const { registration: { installer }, installersStatusConfig: isc } = useAppState()
  const { docsNeedInstallerActionCount, docsNeedSupportActionCount } = project
  // show checklist if action is required
  const [docNav, setDocNav] = useState(
    // use initialNav if set, which overrides autoExpandIfActionNeeded
    initialNav ? initialNav : (
      autoExpandIfActionNeeded && (
        (installer.access === 'support' && docsNeedSupportActionCount > 0) ||
        (installer.isInstaller && docsNeedInstallerActionCount > 0)
      )
    ) ? 'checklist' : '')

  const toggleNav = (s: string) => {
    setDocNav(docNav === s ? '' : s)
  }

  const doneEditingAssignees = () => {
    api.patch(`/loanapps/${project.id}`, {
      authorizedInstallers: project.authorizedInstallers
    }).then(() => {
      toast.success('saved assignees')
      if (onDoneEditingAssignees) onDoneEditingAssignees()
    }).catch(ex => toast.error(ex.error || ex.message, { autoClose: false }))
  }

  const badgeCount = (
    installer.isInstaller ? docsNeedInstallerActionCount : docsNeedSupportActionCount
  )
  const stepReqDocsCount = project.requiredDocuments?.length || 0
  const isAwaitingApplication = project.installersStatus === 'Awaiting Application'
  const needsAction = project.owner === 'Installer'
  const showChangeOrder = isBetweenStatus('Notice to Proceed Issued', project.installersStatus, 'Phase 2 Funded', isc.dict)

  return <div className={'list-group-item project'}>
    <div className={["hdr",
      isAwaitingApplication ? 'pending' : '',
      project.isClosed ? 'closed' : '',
      project.projectType || 'residential'
    ].join(' ')}>
      <div className="pr-type">
        <span title={project.projectType}>
          {project.projectType === 'commercial' ? <FaBuilding /> : null}
          {project.projectType === 'residential' ? <FaHouseChimney /> : null}
          {project.projectType === 'non-profit' ? <FaHandHoldingHeart /> : null}
        </span>
      </div>
      <div className="hdr-cont">
        <div className="pr-name">
          {isAwaitingApplication ? <div className="pr-pending badge text-bg-light mx-3">Pending Application</div> : null}
          {project.customerName || project.businessName || "(Referred)"}
          {project.isClosed ? <div className="pr-closed badge text-bg-secondary">Project Closed</div> : null}
          {needsAction ? <div className="pr-needs-action badge">Needs Action</div> : null}
        </div>
        <div><Link href={`/project/${project.id}`} label={project.address} target="_blank" /></div>
      </div>
    </div>
    <div className="pr-content">
      <div style={{ flex: 3 }}>
        {project.error ? <div className="alert alert-danger">
          <b>ERROR: {project.error}</b>
          <pre className="mt-2">Submission Response: {JSON.stringify(project.submissionResponse, null, 2)}</pre>
        </div> : null}
        <div className="pr-info"><b>Customer Email:</b> {project.email}</div>
        <div className="pr-info"><b>Amount:</b> {dollars(project.loanAmount)}</div>
        <div className="pr-info"><b>Application Date:</b> {project.applicationReceived}</div>
        {project.nTPGiven && <div className="pr-info"><b>NTP Issue Date:</b> {project.nTPGiven}</div>}
        <div className="pr-info"><b>Financing Partner:</b> {project.tenantName}</div>
        <InstallersStatus
          project={project}
          installersStatusConfig={isc}
          onChange={onChange}
        />
        {(project.lastStatusChange?.toString() !== 'Invalid Date') ? <div className="pr-info">
          <b>Last Status Update: </b>{dateAndTime(project.lastStatusChange)}
        </div> : null}
        {isAwaitingApplication ? <>
          <div className="pr-info"><b>Customer Phone:</b> {project.phone}</div>
          <div className="pr-info"><b>Referred:</b> {project.createdDate?.toLocaleString()}</div>
          <div className="pr-info"><b>Last Updated:</b> {project.updatedDate?.toLocaleString()}</div>
          <div className="pr-info"><b>Pre-Submit Step:</b> {project.presubmitStatus
            ? project.presubmitStatus
            : "Unstarted"
          }</div>
        </> : null}

        <NextStep project={project} />
        {project.dueDate ? <div className="pr-info">
          <b>Contract Due: </b>
          <div className="due-dt"><FaExclamationTriangle /> {project.dueDate}</div>
        </div> : null}
      </div>
      <div style={{ flex: 2 }}>
        <ProjectActions
          project={project}
          installer={installer}
        />
        {project.notesVisibleToInstallers ?
          <Comment text={project.notesVisibleToInstallers} />
          : null}
        {stepReqDocsCount > 0 && project.approvedDocsCount === stepReqDocsCount ? (
          <div className="comment bg-success-subtle">
            All documents have been approved for this step. The status should be updated shortly.
          </div>
        ) : null}
      </div>
    </div>
    {project.progress !== undefined ? <ProgressBar
      label="Progress"
      total={100}
      progress={project.progress || 0}
    /> : null}
    <div className="project-buttons">
      <div className="btn-group btn-group-sm">
        <button type="button"
          className={`btn btn-light ${['checklist', 'other'].includes(docNav) ? 'active' : ''}`}
          onClick={() => toggleNav('checklist')}>
          <FaListCheck />
          &nbsp;Checklist&nbsp;
          {docsNeedInstallerActionCount ? <>
            <span className="badge text-bg-warning rounded-pill">{docsNeedInstallerActionCount}</span>
            &nbsp;
          </> : null}
          {docNav === 'checklist' ? <FaChevronDown /> : <FaChevronRight />}
        </button>
        {installer.isSupport ? <button type="button"
          className={`btn btn-light ${['review'].includes(docNav) ? 'active' : ''}`}
          onClick={() => toggleNav('review')}>
          &nbsp;Review&nbsp;
          {docsNeedSupportActionCount ? <>
            <span className="badge text-bg-warning rounded-pill">{docsNeedSupportActionCount}</span>
            &nbsp;
          </> : null}
        </button> : null}
        {showChangeOrder ? <button type="button"
          className={`btn btn-light ${docNav === 'changeOrder' ? 'active' : ''}`}
          onClick={() => toggleNav('changeOrder')}>
          Submit Change Order
        </button> : null}
        <button type="button"
          className={`btn btn-light ${docNav === 'history' ? 'active' : ''}`}
          onClick={() => toggleNav('history')}>
          History
        </button>
      </div>
    </div>
    {
      ['checklist', 'review'].includes(docNav) ? <ProjectRequiredDocuments
        project={project}
        onChange={onChange}
        onOther={() => setDocNav('other')}
        view={docNav}
      /> : null
    }
    {
      docNav === 'other' ? < AdditionalDocumentUpload
        project={project}
        uploadType='other'
        slotConfig={{
          instructions: 'You may upload other documents if needed.',
          slot: 'Other'
        }}
        onChange={proj => {
          onChange(proj)
          setDocNav('history')
        }}
      /> : null
    }
    {/* {installer.isSupport ? <div>
      <button type="button"
        onClick={() => api.get(`/loanapps/${project.id}/nacha`).catch(console.error)}
        className="btn btn-sm btn-link link-secondary p-0"
      >NACHA</button>
    </div> : null} */}
    {docNav === 'history' ? <DocumentUploadHistory project={project} /> : null}
    {
      docNav === 'changeOrder' ? <AdditionalDocumentUpload
        project={project}
        uploadType='change_order'
        slotConfig={{
          instructions: 'Upload the change order or new contract here.',
          slot: 'Change Order Docs'

        }}
        onChange={proj => {
          onChange(proj)
          setDocNav('history')
        }} /> : null
    }
    {
      installer.isAdmin ? <Assignees
        project={project}
        installersMap={installersMap}
        onChange={onChange}
        onDoneEditing={doneEditingAssignees}
      /> : null
    }
  </div >
}

export default ProjectListItem
