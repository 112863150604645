import React, { useState } from 'react'
import { FormInput, SubformProps, Validation } from "."
import { Validate } from '@oneethos/shared'
import { Spinner } from '@fluentui/react'
import { PlaidAuthSelect } from '../../components'
import { AccountBase, Institution, NumbersACH } from 'plaid'
import { useAppState } from '../../hooks'

const Disclaimers = ({ tenantName }) => <>
  <p>
    AUTHORIZATION. Account Holder authorizes {tenantName} to electronically debit
    the account described above (“Account”) (and, if necessary, electronically credit
    the account to erroneous debits) beginning on First Payment Due Date, and on the
    remaining payment dates in amounts as further described in the payment schedule
    section of the Promissory Note/Credit Agreement (“Obligation”).
  </p>

  <p>
    PAYMENT DUE DATE/INSUFFICIENT FUNDS. If the payment due day falls on a date that
    Lender does not process payments, the payment will be deducted on the next day that
    Lender does process payments. If the Account does not have sufficient funds, Lender
    may attempt, but shall have no obligation to continue to attempt to deduct the payment
    from the Account. If the Account has insufficient funds when Lender attempts to deduct
    a payment, Lender may terminate the automatic deduction of payments upon notice to
    Borrower and Account Holder. Until such time as payment is made, Borrower shall
    be responsible to make such payment, and all other payments that may be due on the Obligation.
  </p>

  <p>
    CANCELLATION. Account Holder understands that this Authorization will remain in full
    force and effect until cancelled by me, Borrower or Lender upon written notice. If
    Account Holder or Borrower wish to cancel this Authorization the party requesting
    cancellation will notify Lender at least 30 days prior to the next scheduled payment date.
  </p>

  <p>
    CONSUMER PURPOSE. The Obligations have been incurred for a consumer purpose and I
    understand that this Authorization to charge the Account is not a condition for granting
    credit to Borrower. This Authorization is being granted solely at Borrower and
    Account Holder’s option.
  </p>

  <p>
    ACCOUNT VERIFICATION. Account verified using Plaid Auth to verify Account Holder,
    Depository Institution, Routing Number and Account Number.
  </p >
</>

type PlaidAuth = {
  account: AccountBase
  ach: NumbersACH
  institution: Institution
}

export const AutoDebitAuthorization = ({
  schema,
  formData,
  saving,
  update,
  save
}: SubformProps) => {
  const { tenant } = useAppState()
  const [validation, setValidation] = useState<Record<string, string>>({})
  const [plaidAuth, setPlaidAuth] = useState<PlaidAuth>()
  const [manual, setManual] = useState(
    !!formData.autoDebitAccountNo || tenant?.config?.plaidDisabled
  )

  const validate = () => {
    if (formData.useAutoDebit === 'yes') {
      return Validate.validateFields(schema, formData, [
        'autoDebitInstitution',
        'autoDebitRoutingNo',
        'autoDebitAccountNo',
      ])
    }

    return {}
  }

  const mask = plaidAuth?.account?.mask ? `*******${plaidAuth.account.mask}` : undefined
  const { incentive } = formData

  return <>
    <h2>Auto-Debit Authorization</h2>

    {incentive ? <>
      <p>
        {tenant.config.name} requires you to maintain automatic
        monthly payments to qualify for the <b>{incentive.financingPartner}</b> incentive.
        It is easy to set up auto pay. The bill amount will automatically be withdrawn
        from your checking account.
      </p>
      <p>
        **Incentive terms can be found <a target="_blank" href={incentive.termsUrl}>here</a>.
      </p>
    </> : <p>
      {tenant.config.name} offers a discount when you opt into, and maintain, automatic
      monthly payments. Your rate will be {formData.interest}% with auto pay
      or {formData.interest + 0.25}% without auto pay. It is easy to set up auto
      pay. The bill amount will automatically be withdrawn from your checking account.
    </p>}

    <div className="form-group">
      <label>I'd like to sign up for auto debit</label>
      <select
        className="form-select"
        value={formData.useAutoDebit || 'yes'}
        onChange={ev => update('useAutoDebit', ev.target.value)}
      >
        <option value='no'>No</option>
        <option value='yes'>Yes</option>
      </select>
    </div>

    {formData.useAutoDebit === 'no' ? <div className="alert alert-warning">
      Are you sure you don't want to sign up for auto debit?&nbsp;
      {incentive
        ? 'You may forfeit eligibility for qualifying incentives.'
        : `You will be charged an additional 0.25% on your interest rate.`}
    </div> : null}

    {formData.useAutoDebit === 'yes' ? <>
      {(!manual && !tenant?.config?.plaidDisabled)
        ? <div className="form-group">
          <PlaidAuthSelect
            prompt="Select account to use for Auto-Debit"
            onAuthSelect={({ ach, account, institution }) => {
              setPlaidAuth({ ach, account, institution })
              setManual(true)
              update({
                ...formData,
                autoDebitInstitution: institution.name,
                autoDebitRoutingNo: ach.routing,
                autoDebitAccountNo: ach.account
              })
            }}
            onManual={() => setManual(true)}
          />
        </div>
        : <>
          {!tenant?.config?.plaidDisabled ? <div className="buttons text-start mb-3">
            <button type="button" className="btn btn-secondary"
              onClick={() => {
                setManual(false)
                setValidation({})
              }}>
              Back
            </button>
          </div> : null}
          <FormInput
            field='autoDebitInstitution'
            onChange={s => update('autoDebitInstitution', s)}
            disabled={!!plaidAuth}
            value={formData.autoDebitInstitution}
          />
          <FormInput
            field='autoDebitRoutingNo'
            onChange={s => update('autoDebitRoutingNo', s)}
            disabled={!!plaidAuth}
            value={formData.autoDebitRoutingNo?.toString()}
          />
          <FormInput
            field='autoDebitAccountNo'
            onChange={v => update('autoDebitAccountNo', v)}
            disabled={!!plaidAuth}
            value={mask || formData.autoDebitAccountNo}
          />

          <Disclaimers tenantName={tenant?.config?.name} />
        </>}
    </> : null}

    {(formData.useAutoDebit === 'no' || manual || tenant?.config.plaidDisabled) ? (
      <div className="buttons" style={{ textAlign: 'left' }}>
        <button
          type="button"
          className="btn btn-primary"
          disabled={saving}
          onClick={() => {
            const v = validate()
            setValidation(v)
            if (Object.keys(v).length === 0) {
              save()
            }
          }}
        >{saving ? <Spinner /> : 'Continue'}</button>
        {plaidAuth ? (
          <button type="button"
            className="btn btn-light"
            onClick={() => setPlaidAuth(undefined)}
          >Edit Autopay</button>
        ) : null}
      </div>
    ) : null}
    {
      validation ? (
        <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
      ) : null
    }
  </>
}

export default AutoDebitAuthorization